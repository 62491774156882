import { gsap } from "gsap"; 
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export function homeAnimation() {





  gsap.timeline({
    scrollTrigger: {
      trigger: "#main-header",
      start: "top bottom",
      end: "+=100",
      toggleActions: "restart",
    }
  })
  
 .from("#main-header a", {
  duration: 0.7,
  opacity: 0,
  x: -10, 
  stagger: 0.2,
     
});
 




  gsap.timeline({
    scrollTrigger: {
      trigger: "#hero-section",
      start: "top bottom",
      end: "+=100",
      toggleActions: "restart",
    }
  })
  .from("#hero-section .text-area .text-title", {
    duration: 0.5,
    y: 40,
    opacity: 0,
    ease: "power2.easeInOut",
  })
  .from("#hero-section .text-area .sub-title", {
    duration:  0.5 ,
    y: 40, 
    opacity: 0,
    ease: "power2.easeInOut",
  } )
  .from("#hero-section .text-area .contact-btn", {
    duration: 0.9,
    y: 20,
    opacity: 0,
    ease: "power2.easeInOut",
  },"<")
  .from("#hero-section .image-area", {
    duration: 0.9,
    y: 40,
    opacity: 0,
    ease: "power2.easeInOut",
  },"<")





  gsap.timeline({
    scrollTrigger: {
      trigger: "#about-section",
      start: "top bottom",
      end: "+=100",
      toggleActions: "restart",
    }
  }).from("#about-section .text-area .title-badge", {
    duration: 0.5,
    y: 40, 
    opacity: 0,
    ease: "power2.easeInOut",
  })
  .from("#about-section .text-area .text-title", {
    duration: 0.5,
    y: 40,
    opacity: 0,
    ease: "power2.easeInOut",
  })
  .from("#about-section .about-subtitle", {
    duration: 0.5,
    y: 40,
    opacity: 0,
    ease: "power2.easeInOut",
  },"<")
 .from("#about-section .services-item", {
    duration: 0.5,
    opacity: 0,
    x: -10,
    stagger: 0.2,
       
  },"<");



  gsap.timeline({
    scrollTrigger: {
      trigger: "#features",
      start: "top bottom",
      end: "+=100",
      toggleActions: "restart",
    }
  }).from("#features .features-title .title-badge", {
    duration: 0.5,
    y: 40, 
    opacity: 0,
    ease: "power2.easeInOut",
  })
  .from("#features .features-title .section-title", {
    duration: 0.5,
    y: 40,
    opacity: 0,
    ease: "power2.easeInOut",
  })




  gsap.timeline({
    scrollTrigger: {
      trigger: ".order-management",
      start: "top bottom",
      end: "+=100",
      toggleActions: "restart",
    }
  })
  .from(".order-management .text-area .text-title", {
    duration: 0.5,
    y: 40,
    opacity: 0,
    ease: "power2.easeInOut",
  })
  .from(".order-management .text-area .sub-title", {
    duration:  0.5 ,
    y: 40, 
    opacity: 0,
    ease: "power2.easeInOut",
  } )
  .from(".order-management .text-area .contact-btn", {
    duration: 0.9,
    y: 20,
    opacity: 0,
    ease: "power2.easeInOut",
  },"<")
  .from(".order-management .image-area", {
    duration: 0.9,
    y: 40,
    opacity: 0,
    ease: "power2.easeInOut",
  },"<")



  gsap.timeline({
    scrollTrigger: {
      trigger: ".price-management",
      start: "top bottom",
      end: "+=100",
      toggleActions: "restart",
    }
  })
  .from(".price-management .text-area .text-title", {
    duration: 0.7,
    y: 30,
    opacity: 0,
    ease: "power2.easeInOut",
  })
  .from(".price-management .text-area .sub-title", {
    duration:  0.7,
    y: 30,
    opacity: 0,
    ease: "power2.easeInOut",
  } )
  .from(".price-management .text-area .contact-btn", {
    duration: 0.9,
    y: 20,
    opacity: 0,
    ease: "power2.easeInOut",
  },"<")
  .from(".price-management .image-area", {
    duration: 0.9,
    y: 40,
    opacity: 0,
    ease: "power2.easeInOut",
  },"<")

  gsap.timeline({
    scrollTrigger: {
      trigger: ".landing-feature",
      start: "top bottom",
      end: "+=100",
      toggleActions: "restart",
    }
  }).from(".landing-feature .text-area .soon-badge", {
    duration: 0.5,
    y: 50,
    opacity: 0,
    ease: "power2.easeInOut",
  },"<")
  .from(".landing-feature .text-area .text-title", {
    duration:0.5,  
    y: 50,
    opacity: 0,
    ease: "power2.easeInOut",
  },"<")
  .from(".landing-feature .text-area .sub-title", {
    duration:  0.5,
    y: 50,
    opacity: 0,
    ease: "power2.easeInOut",
  })
  .from(".landing-feature .text-area .contact-btn", {
    duration: 0.5,
    y: 50, 
    opacity: 0,
    ease: "power2.easeInOut",
  })
  .from(".landing-feature  .image-area", {
    duration: 0.5,
    y: 50, 
    opacity: 0,
    ease: "power2.easeInOut",
  },"<")




  gsap.timeline({
    scrollTrigger: {
      trigger: ".global-integration-feature",
      start: "top bottom",
      end: "+=100",
      toggleActions: "restart",
    }
  }).from(".global-integration-feature .text-area .soon-badge", {
    duration: 0.7,
    y: 100,
    opacity: 0,
    ease: "power2.easeInOut",
  },"<")
  .from(".global-integration-feature .text-area .text-title", {
    duration: 0.9,
    y: 100,
    opacity: 0,
    ease: "power2.easeInOut",
  },"<")
  .from(".global-integration-feature .text-area .sub-title", {
    duration:  1,
    y: 100,
    opacity: 0,
    ease: "power2.easeInOut",
  },"<")
  .from(".global-integration-feature .text-area .contact-btn", {
    duration: 0.5,
    y: 10,
    opacity: 0,
    ease: "power2.easeInOut",
  })
   
  gsap.from(".global-integration-feature .global-company", {
    duration: 0.5,
    opacity: 0,
    x: -10,
    stagger: 0.2,
        scrollTrigger: {
      trigger: '.global-integration-feature',
      start: "top center",
      end: '+=100',
      toggleActions: "restart",
    }
  });
  
  gsap.timeline({
    scrollTrigger: {
      trigger: ".landing-footer",
      start: "top bottom",
      end: "+=100",
      toggleActions: "restart",
    }
  })
  .from(".landing-footer .footer-logo .logo", {
    duration: 0.5,
    y: 30,
    opacity: 0,
    ease: "power2.easeInOut",
  })
  .from(".landing-footer .footer-logo span", {
    duration: 0.7,
    y: 30,
    opacity: 0,
    ease: "power2.easeInOut",
  },"<")
  .from(".landing-footer .copyright-text", {
    duration: 0.5,
    y: 30, 
    opacity: 0,
    ease: "power2.easeInOut",
  },"<")
   .from(".social-action a", {
    duration: 0.5,
    opacity: 0,
    x: -10,
    stagger: 0.2,
      
  },"<"); 
} 