import { createApp } from "vue";
import i18n from "./libs/i18n";
import App from "./App.vue";
import router from "./router";
import svgComponents from "./assets/svg";
import "./assets/scss/index.scss";

const app = createApp(App);
 
app.use(router).use(i18n);

svgComponents(app);

app.mount("#app");
